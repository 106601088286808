import {UasZone} from "@unifly-aero/web-common/src/model/UasZone";
import {ViewExtension} from "@unifly-aero/web-common/src/utils/ViewExtension";
import {MetaDataDetailsEditModel} from "@unifly-aero/web-sentry/src/sentry/livemap/uaszone-details-edit-view-extension";
import {NavCanMetaData} from "@unifly-aero/proj-common/src/model/NavCanMetaData";

export class NavcanMetaDataDetailsEdit extends ViewExtension<MetaDataDetailsEditModel> {
    private metaData: NavCanMetaData;
    private currentLanguage: string;
    private geoZone: UasZone;

    activate(model: MetaDataDetailsEditModel) {
        this.geoZone = model.geoZone;
        this.metaData = model.geoZone.metaData;
        this.currentLanguage = model.currentLanguage;

        if (!this.metaData) {
            this.metaData = {secondary_access: null, emergency_contact: null};
        }
        if (!this.metaData.secondary_access && this.hasSecondaryAccess()) {
            this.metaData.secondary_access = {en: null, fr: null, phone: null};
        }
        if (!this.metaData.emergency_contact && this.hasEmergencyContact()) {
            this.metaData.emergency_contact = {en: null, fr: null, phone: null};
        }
        if (!this.metaData.time_designation && this.hasTimeDesignation()) {
            this.metaData.time_designation = {en: null, fr: null};
        }
    }

    hasSecondaryAccess(): boolean {
        return this.hasMetaDataContactInfo();
    }

    hasEmergencyContact(): boolean {
        return this.hasMetaDataContactInfo();
    }

    private hasMetaDataContactInfo(): boolean {
        return this.geoZone.type.includes('AIRSPACE') || this.geoZone.type === 'GRID';
    }

    hasTimeDesignation(): boolean {
        return this.geoZone.airspaceClass === 'F';
    }
}
