import {SupervisorService} from "@unifly-aero/web-sentry/src/services/supervisor/SupervisorService";

const API_SUPERVISOR = '/api/supervisor';

export class NavcanSupervisorService extends SupervisorService {
  public getOngoingPlannedUasOperations(windowStart: Date = new Date(), windowEnd: Date = new Date()) {
    return this.http.createRequest(`${API_SUPERVISOR}/operations?$filter=((geoZone.endTime gt '${windowStart.toISOString()}' and geoZone.startTime lt '${windowEnd.toISOString()}' and status eq 'PUBLISHED') or uas.status eq 'IN_FLIGHT') and rulesetCode notlike 'Microdrones'`)
    .withHeader('Accept', 'application/vnd.geo+json')
    .asGet().send()
    .then((result) => result.content);
  }
}
