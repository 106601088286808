import '@unifly-aero/proj-common/src/styles/theme.scss';

import { Aurelia } from 'aurelia-framework';
import { bootstrap } from 'aurelia-bootstrapper';
import { configure as configureSentry, start as startSentry } from '@unifly-aero/web-sentry/src/main';
import AppConfiguration from '@unifly-aero/proj-sentry/src/config/AppConfiguration';
import {registerMapLayersFactory, registerInformationMenuProvider} from "@unifly-aero/web-common/src/main";
import {NavcanMapLayers} from "@unifly-aero/proj-common/src/layers/navcan-map-layers";
import {AirportDetailsViewExtension} from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/airport-details-view-extension";
import {NavcanMetaDataDetails} from "@unifly-aero/proj-common/src/unifly-luciad/layers/detail-view/navcan-meta-data-details";
import {AirspaceDetailsViewExtension} from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/airspace-details-view-extension";
import {UasZoneDetailsViewExtension} from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/uas-zone-details-view-extension";
import {UaszoneDetailsEditViewExtension} from "@unifly-aero/web-sentry/src/sentry/livemap/uaszone-details-edit-view-extension";
import {NavcanMetaDataDetailsEdit} from "@unifly-aero/proj-sentry/src/sentry/livemap/navcan-meta-data-details-edit";
import { InformationMenuProviderNavcan } from '@unifly-aero/proj-common/src/layout/sidebar/information-menu-provider-navcan';
import {ValidationStatusStyleConverter} from "@unifly-aero/web-common/src/operator/uasoperation/converters/ValidationStatusStyleConverter";
import {NavCanValidationStatusStyleConverter} from "@unifly-aero/proj-common/src/uasoperation/uas-operation-details/NavCanValidationStatusStyleConverter";
import {SupervisorService} from "@unifly-aero/web-sentry/src/services/supervisor/SupervisorService";
import {NavcanSupervisorService} from "@unifly-aero/proj-sentry/src/sentry/service/NavcanSupervisorService";
import {NavcanOperationDetailsSentryWfs} from "@unifly-aero/proj-common/src/unifly-luciad/layers/detail-view/navcan-operation-details-sentry-wfs";
import {OperationDetailsSentryWfs} from "@unifly-aero/web-sentry/src/unifly-luciad/layers/detail-view/operation-details-sentry-wfs";
import {NavcanOperationDetails} from "@unifly-aero/proj-common/src/unifly-luciad/layers/detail-view/navcan-operation-details";
import {OperationDetails} from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/operation-details";
import {NavcanOperationDetailsMyOperation} from "@unifly-aero/proj-common/src/unifly-luciad/layers/detail-view/navcan-operation-details-my-operation";
import { OperationDetailsMyOperation } from '@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/operation-details-my-operation';
import { OperationDetailViewProvider } from "@unifly-aero/web-common/src/utils/OperationDetailViewProvider";
import { NavcanOperationDetailViewProvider } from "@unifly-aero/proj-common/src/utils/NavcanOperationDetailViewProvider";

export function configure(aurelia: Aurelia) {
    configureSentry(aurelia, AppConfiguration);
    startSentry(aurelia);

    registerMapLayersFactory(aurelia.container, NavcanMapLayers);
    registerInformationMenuProvider(aurelia.container, InformationMenuProviderNavcan);

    aurelia.container.registerSingleton(AirportDetailsViewExtension, NavcanMetaDataDetails);
    aurelia.container.registerSingleton(AirspaceDetailsViewExtension, NavcanMetaDataDetails);
    aurelia.container.registerSingleton(UasZoneDetailsViewExtension, NavcanMetaDataDetails);
    aurelia.container.registerSingleton(UaszoneDetailsEditViewExtension, NavcanMetaDataDetailsEdit);
    aurelia.container.registerSingleton(ValidationStatusStyleConverter,  NavCanValidationStatusStyleConverter);
    aurelia.container.registerSingleton(SupervisorService,  NavcanSupervisorService);
    aurelia.container.registerSingleton(OperationDetailsSentryWfs,  NavcanOperationDetailsSentryWfs);
    aurelia.container.registerSingleton(OperationDetailsMyOperation, NavcanOperationDetailsMyOperation);
    aurelia.container.registerSingleton(OperationDetails,  NavcanOperationDetails);
    aurelia.container.registerSingleton(OperationDetailViewProvider, NavcanOperationDetailViewProvider);
}

bootstrap(configure);
