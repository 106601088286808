import { AppConfig } from '@unifly-aero/web-common/src/config/AppConfig';
import SentryAppConfiguration from '@unifly-aero/web-sentry/src/config/AppConfiguration';
import CommonAppConfiguration from '@unifly-aero/proj-common/src/config/AppConfiguration';
import merge from 'lodash.merge';
import { Feature } from "@luciad/ria/model/feature/Feature";
import { UASOperationPermission } from '@unifly-aero/web-common/src/operator/uasoperation';

const RULESET_CODE_MICRODRONES = 'Microdrones'

const AppConfiguration: AppConfig = merge<AppConfig, DeepPartial<AppConfig>, DeepPartial<AppConfig>>(CommonAppConfiguration, SentryAppConfiguration, {
    clientId: 'NavcanSentry',
    clientSecret: '',
    userConfirmationClientId: 'NavcanUserConfirmation',
    userConfirmationClientSecret: '293857aa-7127-47d1-8d94-cd3e448de8da',
    i18n: {
        options: {
            ns: ['proj-sentry', 'proj-common', 'sentry', 'common'],
            fallbackNS: ['proj-sentry', 'proj-common', 'sentry', 'common'],
            defaultNS: 'proj-sentry'
        }
    },
    sentryCanRespondToPermissionFunction: function (permission: UASOperationPermission) {
        // Overriding the product function that checks if the current company
        // of the user is the assignee of the permission request.
        // For navcan, this is irrelevant, we only want to check if the user has the
        // correct area access.
        return hasUserCorrectFeature(this, permission);
    },
    operationToFlighViewFilterFunction: (operation: Feature) => RULESET_CODE_MICRODRONES !== operation?.properties?.rulesetCode
});

function hasUserCorrectFeature(context: any, permission: UASOperationPermission) {
    return context.canUpdateStatus(permission);
}

export default AppConfiguration;
